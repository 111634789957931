import { SiteSlug, sites } from './sites';

// Starts and ends with a letter, with optional letters or - in between
const siteSlugRegex = /^[a-zA-Z][a-zA-Z-]*[a-zA-Z]$/;

export function validateSiteSlug(
  siteSlug?: unknown,
): asserts siteSlug is SiteSlug {
  if (typeof siteSlug !== 'string') {
    throw new TypeError(
      `Site slug should be a string, got '${typeof siteSlug}'`,
    );
  }
  if (siteSlug === '') {
    throw new TypeError('Site slug should not be empty');
  }
  if (!siteSlugRegex.test(siteSlug)) {
    throw new TypeError(`Invalid site slug '${siteSlug}'`);
  }
  if (!(siteSlug in sites)) {
    throw new TypeError(`No such market site '${siteSlug}'`);
  }
}

export function isValidSiteSlug(siteSlug?: unknown): siteSlug is SiteSlug {
  try {
    validateSiteSlug(siteSlug);
  } catch (unused) {
    return false;
  }
  return true;
}
